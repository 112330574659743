import React from 'react';
import { AppBar, Box, Button, Container, CssBaseline, ListItemIcon, ListItemText, Toolbar, Menu, Tooltip, IconButton, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { Login, Twitter, Facebook, LinkedIn, YouTube, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { ThemeColour } from '@sanedi.epc/theme';
import H5 from '@sanedi.epc/components/heading/h5';
import emotionStyled from '@emotion/styled';

const StyledToolbar = styled(Toolbar)(() => ({
    flex: 1,
    width: '100%',
    gap: 20,
    height: 82
}));

const AppBarLink = styled(Button)(() => ({
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '8px',
    color: ThemeColour.SanediDarkGreen,
    transition: 'ease-in-out 200ms background, ease-in-out 200ms color, ease-in-out 200ms padding, ease-in-out 200ms border-radius',
}));

const AppBarMenuItemLink = styled(Button)(() => ({
    fontSize: '1.1em',
    fontWeight: 500,
    width: '100%',
    textTransform: 'none',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    color: ThemeColour.SanediDarkGreen,
    transition: 'ease-in-out 200ms background, ease-in-out 200ms color, ease-in-out 200ms padding, ease-in-out 200ms border-radius',
}));

const CategoryName = styled(Typography)(() => ({
    fontSize: '1.2em',
    fontWeight: 500,
    width: '100%',
    padding: '12px',
    display: 'flex',
    textTransform: 'none',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: 6,
    color: ThemeColour.SanediDarkGreen,
    transition: 'ease-in-out 200ms background, ease-in-out 200ms color, ease-in-out 200ms padding, ease-in-out 200ms border-radius',
}));

const SidebarItemIcon = styled(ListItemIcon)(() => ({
    borderRight: '1px solid #ffffff',
    minWidth: '40px',
    width: '40px'
}));

const SidebarItemText = styled(ListItemText)(() => ({
    paddingLeft: '14px'
}));

const FooterLink = emotionStyled.a`
color: white;
`

interface HomePageContainerProps extends React.PropsWithChildren {
    noPadding?: boolean;
}

const HomePageContainer = React.memo(({ children, noPadding = false }: HomePageContainerProps) => {
    const navigate = useNavigate();

    const [anchorElAbout, setAnchorElAbout] = React.useState<null | HTMLElement>(null);
    const openAbout = Boolean(anchorElAbout);
    const handleClickAbout = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAbout(event.currentTarget);
    }, []);
    const handleCloseAbout = React.useCallback(() => {
        setAnchorElAbout(null);
    }, []);

    const [anchorElNews, setAnchorElNews] = React.useState<null | HTMLElement>(null);
    const openNews = Boolean(anchorElNews);
    const handleClickNews = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElNews(event.currentTarget);
    }, []);
    const handleCloseNews = React.useCallback(() => {
        setAnchorElNews(null);
    }, []);

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
            <CssBaseline />
            <AppBar position="static" sx={{ height: 82, padding: 0, borderRadius: 0, background: '#fff', boxShadow: '0px 0px 40px 0px rgba(0,0,0,0.2)', borderBottom: '2px solid rgba(57, 140, 39, 0.5)' }}>{/* borderBottomRightRadius: 100, borderRight: '1px solid rgba(57, 140, 39, 0.5)' */}
                <Container maxWidth={false} sx={{ ml: 0, mr: 2, width: '100%' }}>
                    <StyledToolbar>
                        <Link to='/'>
                            <img height={64} src='/eei.png' alt='Energy Efficiency Initiative Logos' />
                        </Link>
                        <Tooltip title='National Building Energy Performance Register'>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <H5 sx={{ color: ThemeColour.SanediDarkGreen, textDecoration: 'none', fontWeight: '600' }}>NBEPR</H5>
                            </Link>
                        </Tooltip>
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' }, flexGrow: 1, gap: '20px', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <AppBarLink
                                variant='text'
                                id="about"
                                aria-controls={openAbout ? 'about-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openAbout ? 'true' : undefined}
                                onClick={handleClickAbout}
                            >
                                About
                                <ExpandMore />
                            </AppBarLink>
                            <Menu
                                id="about-menu"
                                anchorEl={anchorElAbout}
                                open={openAbout}
                                onClose={handleCloseAbout}
                                MenuListProps={{
                                    'aria-labelledby': 'about',
                                    sx: { width: 200 }
                                }}
                            >
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/about-us' } as any}
                                >
                                    About Us
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/team' } as any}
                                >
                                    The Team
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/info' } as any}
                                >
                                    About EPCs
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/legislation' } as any}
                                >
                                    Legislation
                                </AppBarMenuItemLink>
                            </Menu>

                            <AppBarLink
                                variant='text'
                                id="info"
                                aria-controls={openNews ? 'news-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openNews ? 'true' : undefined}
                                onClick={handleClickNews}
                            >
                                Information
                                <ExpandMore />
                            </AppBarLink>
                            <Menu
                                id="news-menu"
                                anchorEl={anchorElNews}
                                open={openNews}
                                onClose={handleCloseNews}
                                MenuListProps={{
                                    'aria-labelledby': 'news',
                                    sx: { width: 200 }
                                }}
                            >
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/articles' } as any}
                                >
                                    Articles
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/faq' } as any}
                                >
                                    FAQs
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    variant='text'
                                    {...{ to: '/gallery' } as any}
                                >
                                    Gallery
                                </AppBarMenuItemLink>
                            </Menu>

                            <AppBarLink
                                LinkComponent={Link}
                                variant='text'
                                {...{ to: '/sanas-bodies' } as any}
                            >
                                Registered EPC Professionals
                            </AppBarLink>

                            <AppBarLink
                                LinkComponent={'a'}
                                variant='text'
                                href='https://pas-sa.co.za/epc/qa_register/'
                            >
                                Register as an EPC Quality Assurer
                            </AppBarLink>

                            <AppBarLink
                                LinkComponent={Link}
                                variant='text'
                                {...{ to: '/enquiry' } as any}
                            >
                                Contact&nbsp;Us
                            </AppBarLink>

                            <img height={57} src='/sanedi-logo.png' alt='SANEDI Logo' />

                            <Button
                                LinkComponent={Link}
                                {...{ to: '/login' } as any}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/login');
                                }}
                                sx={{
                                    height: 50,
                                    background: ThemeColour.SanediGreen,
                                    color: 'white',
                                    transition: 'ease-in-out 200ms all',
                                    minWidth: 144,
                                    px: 2,
                                    "& svg": {
                                        transition: 'ease-in-out 200ms all',
                                        fill: 'white',
                                    },
                                    "& span": {
                                        fontWeight: 500
                                    },
                                    "&:hover": {
                                        background: ThemeColour.SanediDarkGreen,
                                        fontWeight: 'bold'
                                    }
                                }}>
                                <SidebarItemIcon>
                                    <Login />
                                </SidebarItemIcon>
                                <SidebarItemText>
                                    Log&nbsp;In
                                </SidebarItemText>
                            </Button>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' }, flexDirection: 'row-reverse', flexGrow: 1 }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                            >
                                <MenuIcon htmlColor={ThemeColour.SanediDarkGreen} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                keepMounted
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
                                }}
                                MenuListProps={{ sx: { width: 300 } }}
                            >
                                <Divider />
                                <CategoryName>
                                    About
                                    <ExpandMore />
                                </CategoryName>
                                <Divider />
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/about-us' } as any}
                                >
                                    About Us
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/team' } as any}
                                >
                                    The Team
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/info' } as any}
                                >
                                    About EPCs
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/legislation' } as any}
                                >
                                    Legislation
                                </AppBarMenuItemLink>
                                <Divider />
                                <CategoryName>
                                    Information
                                    <ExpandMore />
                                </CategoryName>
                                <Divider />
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/articles' } as any}
                                >
                                    Articles
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/faq' } as any}
                                >
                                    FAQs
                                </AppBarMenuItemLink>
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ paddingLeft: 4 }}
                                    variant='text'
                                    {...{ to: '/gallery' } as any}
                                >
                                    Gallery
                                </AppBarMenuItemLink>
                                <Divider />
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ fontSize: '1.2em' }}
                                    variant='text'
                                    {...{ to: '/sanas-bodies' } as any}
                                >
                                    Registered EPC Professionals
                                </AppBarMenuItemLink>
                                <Divider />

                                <AppBarMenuItemLink
                                    LinkComponent={'a'}
                                    sx={{ fontSize: '1.2em' }}
                                    variant='text'
                                    href='https://pas-sa.co.za/epc/qa_register/'
                                >
                                    Register as an EPC Quality Assurer
                                </AppBarMenuItemLink>
                                <Divider />
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ fontSize: '1.2em' }}
                                    variant='text'
                                    {...{ to: '/enquiry' } as any}
                                >
                                    Contact Us
                                </AppBarMenuItemLink>
                                <Divider />
                                
                                <AppBarMenuItemLink
                                    LinkComponent={Link}
                                    sx={{ fontSize: '1.2em' }}
                                    variant='text'
                                    {...{ to: '/login' } as any}
                                >
                                    Log In
                                </AppBarMenuItemLink>

                                <Divider />
                                <Box py={2} display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img height={57} src='/sanedi-logo.png' alt='SANEDI Logo' />
                                </Box>
                                <Divider />
                                <Box pt={2} display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <img height={57} src='/dmre-logo.png' alt='DMRE Logo' />
                                </Box>
                            </Menu>
                        </Box>
                    </StyledToolbar>
                </Container>
            </AppBar>
            <Box component="main" sx={{ flexGrow: 1, padding: noPadding ? '0' : '40px 0 0 0' }}>
                {children}
            </Box>
            <footer style={{
                background: ThemeColour.SanediGreen,
                height: 64,
                width: '100%',
                bottom: 0,
                right: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 20px'
            }}>
                <span style={{ flex: 1, color: 'white' }}>Copyright &copy; {new Date().getFullYear()} SANEDI. All Rights Reserved.</span>
                <img style={{ background: 'white', paddingLeft: 12, paddingRight: 12, borderRadius: 6, marginRight: 30 }} height={52} src='/dmre-logo.png' alt='DMRE Logo' />
                <span style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 30,
                    paddingRight: 100
                }}>
                    <FooterLink href='https://twitter.com/sanedi_org' target='_blank'>
                        <Twitter />
                    </FooterLink>
                    <FooterLink href='https://web.facebook.com/Sanedi.govemes/' target='_blank'>
                        <Facebook />
                    </FooterLink>
                    <FooterLink href='https://www.linkedin.com/company/sanedi/' target='_blank'>
                        <LinkedIn />
                    </FooterLink>
                    <FooterLink href='https://www.youtube.com/channel/UCNzHRnxtqGiqzVVyG0-nERw' target='_blank'>
                        <YouTube />
                    </FooterLink>
                </span>
            </footer>
        </Box>
    );
});

export default HomePageContainer;